.confirm-data {
    margin: 30px 0px 0px;
}
.report-0 .quadrilles > h5 {
    margin: 30px 0px 20px;
}
.confirm-data .input-container {
    margin: 0px;
}
.confirm-data p {
    margin: 20px 0px 0px;
    padding: 0px;
}
.confirm-data .input-container input {
    border-bottom: 0.5px solid gray;
    height: 30px;
    margin: 0px;
    padding: 0px;
    background: none;
}
.btn-report {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin: 30px 0px 0px;
    color: white;
}