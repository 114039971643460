/* Navbar Left */
.navbar {
    overflow: hidden;
}
.navbar-left {
    clip-path: polygon(0 1%, 45% 1%, 52% 0, 60% 1%, 100% 1%, 100% 100%, 0 100%);
    background: rgb(24, 106, 153);
    position: fixed;
    left: 0;
    top: 50px;
    width: 90px;
    height: 0%;
    border-radius: 5px;
    color: white;
    margin: 10px;
    transition: all 0.3s;
}
.navbar-left.active {
    height: 91%;
    overflow: auto;
}
.navbar .navbar-header .image-container {
    width: 100%;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
}
.navbar .navbar-header .image-container:hover {
    background: green;
}
.navbar .navbar-header .image-container .logo-image {
    width: 80px;
    height: 80px;
}
.navbar .navbar-left hr {
    width: 50%;
    margin: auto;
}

/* Navbar Left actions */
.navbar .navbar-body ul {
    list-style: none;
    padding: 0px;
    margin: auto;
    height: 100%;
}
.navbar .navbar-body ul li {
    height: 20px;
    padding: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
}
.navbar .navbar-body ul li:hover {
    background: rgb(1, 172, 1);
}
.navbar .navbar-body ul li.active {
    background: rgb(55, 190, 214);
}
.navbar .navbar-body ul li .svg-icon {
    width: 25px;
    height: 25px;
}

/* Navbar Rigth */
.navbar .navbar-top-right {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 60px;
    background: transparent;
    z-index: 1;
    background: red;
}
.navbar .profile-content > ul {
    list-style: none;
    height: auto;
    width: auto;
    top: 60px;
    right: 50px;
    padding: 0px;
    position: fixed;
    border-radius: 5px;
    background: rgb(241, 241, 241);
    animation: profile-options-disappear 0.3s;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    box-shadow: 0px 0px 10px 6px rgba(58, 58, 58, 0.2);
}
.navbar .profile-content > ul.active {
    display: block;
    animation: profile-options-appear 0.3s;
}
.navbar .profile-content > ul li {
    padding: 10px 20px 10px 20px;
    font-size: 15px;
    cursor: pointer;
}
.navbar .profile-content > ul li:hover {
    background: rgb(234, 253, 250);
}
.navbar .profile-content > .btn-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 3px;
    right: 20px;
    border: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    background: rgb(24, 106, 153);
    z-index: 1;
    margin: 10px 0px 0px 0px;
    outline-style: none;
}
.navbar .profile-content > .btn-profile > .profile-image {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}
.navbar .navbar-top > .btn-toogle-navbar {
    width: 45px;
    height: 45px;
    margin-left: 35px;
    position: fixed;
    top: 10px;
    left: 0;
    background: rgb(24, 106, 153);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: auto;
    border-radius: 50%;
    outline-style: none;
}
.navbar .navbar-top > .btn-toogle-navbar .svg-icon {
    color: white;
}
.navbar .navbar-top > button .svg-icon {
    width: 24px;
    height: 24px;
    color: #202020;
}
.navbar .navbar-top-right .profile-container > button:nth-child(1) {
    margin-right: 20px;
}
.navbar .navbar-top-right .profile-container > button:nth-child(2) {
    margin-right: 20px;
}

/* Animations */
@keyframes profile-options-appear {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    } 
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes profile-options-disappear {
    0% {
        opacity: 1;
        transform: translateY(0px);
    } 
    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}