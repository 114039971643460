.modal .modal-content .modal-body .input-container {
    position: relative;
}
.modal .modal-content .modal-body .input-container .svg-icon {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 10px;
    left: 5px;
}
.modal .modal-content .modal-body .btn-image,
.modal .modal-content .modal-body .btn-clear-image {
    width: 50%;
    margin: 10px auto;
    display: block;
}
.modal .modal-content .modal-body .btn-clear-image {
    margin: 20px auto;
}
.modal .modal-content .modal-body .btn-image .svg-icon,
.modal .modal-content .modal-body .btn-clear-image .svg-icon {
    height: 24px;
    width: 24px;
}
.modal .modal-content .modal-body .image-selected-container {
    display: grid;
    grid-template-columns: 50% 50%;
}
.modal .modal-content .modal-body .image-selected-container .image-selected {
    padding: 0px 20px;
    width: 100%;
    height: auto;
    max-height: 300px;
}
.modal .modal-content .modal-body .image-selected-container .image-selected p {
    margin: 0px;
}
.modal .modal-content .modal-body .image-selected-container .image-selected img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 270px;
}
.modal .modal-content .modal-body .image-selected-container .image-selected .no-image {
    color: rgb(223, 1, 1);
    font-weight: 600;
}
.btn-submit {
    width: 50%;
    margin: 30px auto 10px auto;
    display: block;
}

/* Responsive */
@media (max-width: 768px) {
    .modal .modal-content .modal-body .btn-image {
        width: 100%;
    }
    .modal .modal-content .modal-body .image-selected-container {
        grid-template-columns: 20% 80%;
    }
}