.modal .modal-content .modal-body .date-container .date-info {
    display: block;
    width: 100%;
}
.date-container .date-of-seniority p {
    line-height: normal;
}
.date-container .date-of-seniority .no-date {
    color: rgb(77, 1, 1);
    font-weight: 600;
}
.modal .modal-content .modal-body .btn-submit {
    margin: 50px auto;
}