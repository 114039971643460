.home {
    width: 100%;
    background: #f2f2f2;
    height: 100vh;
}
.home .home-container {
    width: 100%;
    transition: all 0.3s;
}
.home .home-container > h4 {
    margin: 0px 0px 20px 0px;
}
.home .home-container .home-website > h4,
.home .home-container .home-employees > h4 {
    margin: 0px 0px 20px 0px;
}

.home .home-container .home-website .card,
.home .home-container .home-employees .card {
    display: flex;
    justify-content: center;
    position: relative;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 10px;
}
.home .home-container .home-website .card:hover,
.home .home-container .home-employees .card:hover {
    cursor: pointer;
    background: rgb(229, 255, 249);
}
.home .home-container .home-website .card .column,
.home .home-container .home-employees .card .column {
    background: rgb(0, 162, 255);
    width: 10px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.home .home-container .home-website .card .data,
.home .home-container .home-employees .card .data {
    padding: 0px 10px;
    margin: 0px;
    margin-right: auto;
    width: 100%;
}
.home .home-container .home-website .card .data .image-container,
.home .home-container .home-employees .card .data .image-container {
    background: rgb(221, 255, 239);
    float: right;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 10px 5px;
}
.home .home-container .home-website .card .data .image-container .svg-icon,
.home .home-container .home-employees .card .data .image-container .svg-icon {
    height: 20px;
    width: 20px;
    color: rgb(83, 140, 206);
}
.home .home-container .home-website .card .data p,
.home .home-container .home-employees .card .data p {
    margin: 10px 0px;
    padding: 0px 10px 0px 10px;
}
.home .home-container .home-website .card .data p:nth-child(2),
.home .home-container .home-employees .card .data p:nth-child(2) {
    font-size: 18px;
    font-weight: bold;
}
.home .home-container .home-website .card .data-content,
.home .home-container .home-employees .card .data-content {
    width: 100%;
    position: relative;
}
.home .home-container .home-website .card .data-content p:last-child,
.home .home-container .home-employees .card .data-content p:last-child {
    position: absolute;
    right: 0px;
}
.home .home-container .home-website .card .data-content .loading-data,
.home .home-container .home-employees .card .data-content .date {
    font-size: 14px !important;
}
.status-succeeded {
    color: green;
}
.status-unsucceeded {
    color: rgb(128, 0, 0);
}




/* .home .home-container .home-website .card .card-header,
.home .home-container .home-employees .card .card-header {
    display: flex;
    position: relative;
}
.home .home-container .home-website .card .card-header .text > p,
.home .home-container .home-employees .card .card-header .text > p {
    position: absolute;
    right: 15px;
    top: 0px;
    font-weight: 400;
}
.home .home-container .home-website .card .card-header .text > p:nth-child(1),
.home .home-container .home-employees .card .card-header .text > p:nth-child(1) {
    font-size: 20px;
}
.home .home-container .home-website .card .icon-container,
.home .home-container .home-employees .card .icon-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 10px 5px;
}
.home .home-container .home-website .card .icon-container .svg-icon,
.home .home-container .home-employees .card .icon-container .svg-icon {
    width: 30px;
    height: 30px;
    color: white;
}
.home .home-container .home-website .card .card-body,
.home .home-container .home-employees .card .card-body {
    margin: 0px 0px 40px 0px;
}
.home .home-container .home-website .card .card-body .info-text,
.home .home-container .home-employees .card .card-body .info-text {
    display: flex;
    position: relative;
    height: 10px;
}
.home .home-container .home-website .card .card-body .info-text p:nth-child(1),
.home .home-container .home-employees .card .card-body .info-text p:nth-child(1) {
    margin-left: 10px;
}
.home .home-container .home-website .card .card-body .info-text p:nth-child(2),
.home .home-container .home-employees .card .card-body .info-text p:nth-child(2) {
    position: absolute;
    right: 20px;
    font-weight: bold;
    font-size: 13px;
}
.home .home-container .home-website .card hr,
.home .home-container .home-employees .card hr {
    width: 92%;
    margin: auto;
    padding: 0px;
    border: 0.10px solid rgb(224, 224, 224);
}
.home .home-container .home-website .card .card-footer button,
.home .home-container .home-employees .card .card-footer button {
    background: tomato;
    border-radius: 5px;
}
.home .home-container .home-website .card .card-footer button .svg-icon,
.home .home-container .home-employees .card .card-footer button .svg-icon {
    color: white;
} */