.add .modal .modal-content {
    height: auto;
    animation: modal-appear 0.5s;
}

@keyframes modal-appear {
    0% {
        width: 0px;
        height: 0px;
    }

    100% {
        width: 95%;
        height: auto;
    }
}