.images-home {
    overflow: hidden;
}
.images-home .images-home-container .images-home-header .btn-add {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin: 30px 0px 0px 0px;
}
.images-home .images-home-container .images-home-header .btn-add .svg-icon {
    height: 20px;
    width: 20px;
    margin-right: 2px;
}

/* BODY */
.images-home .images-home-container .image-home-body {
    margin: 40px 0px 0px 0px;
}
.images-home .images-home-container .image-home-body .card {
    width: auto;
    height: 200px;
    border-radius: 10px;
    display: flex;
    position: relative;
    transition: all 0.3s;
    z-index: 1;
}
.images-home .images-home-container .image-home-body .card:hover {
    height: auto;
}
.images-home .images-home-container .image-home-body .card:hover .info {
    margin: 200px 0px 0px 0px;
    opacity: 1;
    transition: all 0.3s;
    max-height: 100%;
    width: 100%;
    visibility: visible;
}
.images-home .images-home-container .image-home-body .card img {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.images-home .images-home-container .image-home-body .card .image-container > img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
}
.images-home .images-home-container .image-home-body .card .info {
    margin: 0px 0px 0px 0px;
    opacity: 0;
    transition: all 0.3s;
    height: 0%;
    visibility: hidden;
    z-index: 0;
}
.images-home .images-home-container .image-home-body .card .info p {
    margin: 10px 0px 5px 0px;
    transition: all 0.3s;
}
.images-home .images-home-container .image-home-body .card .info p:nth-child(1) {
    font-weight: bold;
}
.images-home .images-home-container .image-home-body .card .info p:nth-child(3) {
    font-weight: 700;
    font-size: 13px;
}
.images-home .images-home-container .image-home-body .card .info .btn-actions {
    float: right;
    margin: 5px 0px 0px auto;
}
.images-home .images-home-container .image-home-body .card .info button {
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 0px 20px 0px 0px;
}
.images-home .images-home-container .image-home-body .card .info .btn-edit:hover {
    box-shadow: 0px 0px 6px 4px rgba(58, 58, 58, 0.3);
}
.images-home .images-home-container .image-home-body .card .info .btn-delete:hover {
    box-shadow: 0px 0px 6px 4px rgba(58, 58, 58, 0.3);
}