.sign-in {
  height: 100vh;
}
.sign-in .error {
  color: white;
  background: rgb(143, 0, 0);
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 2px;
  text-align: center;
}
.sign-in .logo-image {
  height: 40vw;
}
.sign-in main {
  height: 100%;
  margin: auto;
}
.sign-in form {
  margin: auto;
  width: 100%;
  padding: 8px;
}
.sign-in main .image-container {
  background: rgb(0, 68, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Inputa */
.sign-in .svg-icon {
    width: 24px;
    height: 24px;
    color: rgb(100, 100, 100);
    position: absolute;
    top: 15px;
    left: 10px;
}

/* The container */
.sign-in .checkbox-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.sign-in .checkbox-container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  bottom: 0;
  border-radius: 5px;
}

/* Create a custom checkbox */
.sign-in .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 18px;
  width: 18px;
  /* background-color: rgb(235, 235, 235); */
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.sign-in .checkbox-container input[type="checkbox"]:hover ~ .checkmark {
  background-color: rgb(206, 206, 206);
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.sign-in .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.sign-in .checkbox-container input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.sign-in .checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sign-in .checkbox-container label {
    font-size: 15px;
    padding: 0px;
    margin: 0px;
}
