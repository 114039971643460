.modal .modal-content .modal-body .show-image-selected-container {
    width: 100%;
    height: 100%;
}
.modal .modal-content .modal-body .show-image-selected-container .show-product img {
    height: 50%;
    width: 50%;
}
.modal .modal-content .show-modal-body .input-container input,
.modal .modal-content .show-modal-body .input-container select {
    cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
    .modal .modal-content .modal-body .show-image-selected-container {
        display: block;
    }
    .modal .modal-content .modal-body .show-image-selected-container .show-product img {
        height: 100%;
        width: 100%;
    }
}