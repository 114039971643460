.loader-container-1 {
    width: 100%;
    height: 100%;
    background: #09bcdb;
    /* animation: appear 0.5s; */
    position: fixed;
    z-index: 10;
}
.loader-container-2 {
    width: 100%;
    height: 100%;
    background: rgb(44, 44, 44);
    /* animation: appear 1s; */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
.loader-container .loader-container-1.active {
    animation: appear 0.5s;
}
.loader-container .loader-container-1.inactive {
    width: 0%;
    animation: disappear 0.5s;
}
.loader-container .loader-container-2.active {
    animation: appear 1s;
}
.loader-container .loader-container-2.inactive {
    width: 0%;
    animation: disappear 1s;
}
.loader-container .loader-content.inactive {
    opacity: 0;
    display: none;
    transition: all 0.3s;
    background: red;
}
.loader-content .line {
    width: 350px;
    height: 50px;
    background: white;
    position: fixed;
    top: 45%;
    left: 50%;
    bottom: 0;
    right: 0;
    transform:translate(-50%, -50%);
    z-index: 10;
    transition: all 0.3s;
}
.loader-content .line.disappear {
    opacity: 0;
    display: none;
    transition: all 0.3s;
    background: red;
}
.loader-content .line-1 {
    margin: 0px 0px;
    animation: line-move 0.7s infinite alternate-reverse;
    animation-delay: 0.3s;
    transition: all 0.3s;
    
}
.loader-content .line-2 {
    margin: 49px 0px;
    animation: line-move 1s infinite alternate-reverse;
    animation-delay: 0.5s;
    transition: all 0.3s;
}
.loader-content .line-3 {
    margin: 98px 0px;
    animation: line-move 0.7s infinite alternate-reverse;
    animation-delay: 0.7s;
    transition: all 0.3s;
}

/* Responsive */
@media (max-width: 768px) {
    .loader-content .line {
        width: 50%;
        height: 30px;
    }
    .loader-content .line-2 {
        margin: 29px 0px;
    }
    .loader-content .line-3 {
        margin: 58px 0px;
    }
}

/* Animations */
/* Loader container */
@keyframes appear {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@keyframes disappear {
    0% {
        opacity: 1;
        width: 100%;
    }

    100% {
        opacity: 0;
        width: 0%;
    }
}

/* Lines */
@keyframes line-move {
    0% {
        width: 10%;
        left: 50%;
    }
    100% {
        width: 50%;
        left: 50%;
    }
}