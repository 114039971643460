.modal .modal-content .date-range-container {
    margin: 0px auto 30px auto;
}
.modal .modal-content .date-range-container button {
    width: 50%;
    margin: auto;
    display: block;
}
.modal .modal-content .date-range-container .initial-date p,
.modal .modal-content .date-range-container .end-date p {
    text-align: center;
}
.modal .modal-content .btn-date .svg-icon {
    height: 24px;
    width: 24px;
}