/* Header */
.about-us .about-us-header {
    width: 100;
    margin: 0px;
}
.about-us .about-us-header .card-info p:last-child,
.about-us .about-us-body .card-info-container .card-certifications-info p:last-child {
    text-align: justify;
    font-size: 20px;
    line-height: 1.5;
}
.about-us .about-us-header  .card-info {
    padding: 20px 7%;
}
.about-us .about-us-header .card-info-gray {
    background: #F8F8F8;
    width: 100%;
}

/* Body */
.about-us .about-us-body {
    width: 100%;
}
.certifications-container {
    padding: 50px 0px;
}
.about-us .about-us-body .certifications-container > p:first-child,
.about-us .about-us-body > p,
.about-us .about-us-body  .card-info-container {
    padding: 0px 7%;
}

.about-us .about-us-body  .card-info-container .card-certifications-info {
    margin: 20px 30px 0px 0px;
    box-shadow: 0px 0px 8px 4px rgba(194, 222, 255, 0.4);
    padding: 10px 25px;
}
.about-us .about-us-body  .card-info-container .card-certifications-info:hover {
    box-shadow: 0px 0px 8px 4px rgba(169, 209, 255, 0.4);
}
.about-us .about-us-body  .card-info-container .card-certifications-info p:first-child {
    font-size: 20px;
    font-weight: 600;
}
.about-us .about-us-body  .card-info-container .card-certifications-info p:last-child {
    font-size: 15px;
}


/* Responsive */
@media (min-width: 1500px) {
    .about-us .about-us-body  .card-info-container .card-certifications-info p:last-child {
        font-size: 18px;
    }
}