.date-container {
    margin: -25px 0px;
}
.date-select-container {
    display: grid;
    grid-template-columns: 50% 50%;
}
.date-select-container .month-container {
    float: left;
    width: 100%;
    position: absolute;
    left: 0;
}
.date-select-container .month-container input {
    width: 90%;
    margin: auto;
}
.date-select-container .year-container input  {
    width: 70%;
    margin: auto;
}