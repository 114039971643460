.grid-admin-page .services-ws .services-ws-header .btn-add {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin: 30px 0px 0px 0px;
}
.grid-admin-page .services-ws .services-ws-header .btn-add .svg-icon {
    height: 20px;
    width: 20px;
    margin-right: 2px;
}

/* BODY */
.services-ws .services-ws-body .cards-container {
    margin: 40px 0px 0px 0px;
}
.services-ws .services-ws-body .cards-container .card {
    width: auto;
    height: 200px;
    border-radius: 10px;
    display: flex;
    position: relative;
    transition: all 0.3s;
    z-index: 1;
}
.services-ws .services-ws-body .cards-container .card:hover {
    height: auto;
}
.services-ws .services-ws-body .cards-container .card:hover .info {
    margin: 200px 0px 0px 0px;
    opacity: 1;
    transition: all 0.3s;
    max-height: 100%;
    width: 100%;
    visibility: visible;
}
.services-ws .services-ws-body .cards-container .card img {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.services-ws .services-ws-body .cards-container .card .image-container > img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
}
.services-ws .services-ws-body .cards-container .card .info {
    margin: 0px 0px 0px 0px;
    opacity: 0;
    transition: all 0.3s;
    height: 0%;
    visibility: hidden;
    z-index: 0;
}
.services-ws .services-ws-body .cards-container .card .info p {
    margin: 10px 0px 5px 0px;
    transition: all 0.3s;
}
.services-ws .services-ws-body .cards-container .card .info p:nth-child(1) {
    font-weight: bold;
}
.services-ws .services-ws-body .cards-container .card .info p:nth-child(3) {
    font-weight: 700;
    font-size: 13px;
}
.services-ws .services-ws-body .cards-container .card .info .btn-actions {
    float: right;
    margin: 5px 0px 0px auto;
}
.services-ws .services-ws-body .cards-container .card .info button {
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 0px 20px 0px 0px;
}
.services-ws .services-ws-body .cards-container .card .info .btn-edit:hover {
    box-shadow: 0px 0px 6px 4px rgba(58, 58, 58, 0.3);
}
.services-ws .services-ws-body .cards-container .card.info .btn-delete:hover {
    box-shadow: 0px 0px 6px 4px rgba(58, 58, 58, 0.3);
}



/* .grid-admin-page .services-ws .services-ws-body {
    margin: 40px 0px 0px 0px;
    height: auto;
}
.grid-admin-page .services-ws .services-ws-body .card {
     height: auto;
     position: relative;
     display: grid;
     grid-template-rows: 150px auto;
}
.grid-admin-page .services-ws .services-ws-body .card img {
    width: 90%;
    height: 100%;
    margin: auto;
    display: block;
    object-fit: cover;
    border-radius: 20px;
}
.grid-admin-page .services-ws .services-ws-body .card .card-image{
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
}
.grid-admin-page .services-ws .services-ws-body .card .card-content {
    padding: 15px;
    position: relative;
}
.grid-admin-page .services-ws .services-ws-body .card .card-content p {
    margin: 10px 0px 15px 0px;
}
.grid-admin-page .services-ws .services-ws-body .card .card-content > p:first-child {
    font-weight: bold;
    font-size: 20px;
}
.grid-admin-page .services-ws .services-ws-body .card .card-content p:nth-child(2) {
    display: -webkit-box;   
    -webkit-line-clamp: 3;   
    -webkit-box-orient: vertical;     
    overflow: hidden;
}
.btn-dots {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 30px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-card-go {
    background: rgb(71, 255, 200);
}
.btn-card-go .svg-icon {
    color: white;
}
.grid-admin-page .services-ws .services-ws-body .card .svg-icon {
    width: 24px;
    height: 24px;
}
.grid-admin-page .services-ws .services-ws-body .card .card-content hr {
    width: 70%;
    margin: 0px auto;
    border: 0.5px solid rgb(226, 226, 226)
}
.services-ws .btn-options {
    position: absolute;
    right: 20px;
    top: 40px;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
}
.services-ws .btn-options.active {
    opacity: 1;
}
.services-ws .btn-options > ul {
    width: auto;
    background: rgb(209, 255, 249);
    list-style: none;
    padding: 0px;
    border-radius: 5px;
}
.services-ws .btn-options > ul li {
    height: auto;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
}
.services-ws .btn-options > ul li:hover {
    background: rgb(95, 231, 255);
}
.services-ws .btn-options > ul li:hover ~ .svg-icon {
    color: rgb(95, 231, 255);
}
.services-ws .btn-options > ul li .svg-icon {
    margin: 0px 20px 0px 00px;
    color: rgb(87, 115, 117);
} */