/* Last Filter Form */
.drag-employees .btn-back {
    background: rgb(199, 0, 0);
}
.drag-employees .btn-select {
    background: rgb(157, 255, 0);
}
.drag-employees .btn-next {
    background: green;
}
.drag-employees .quadrilles > h5 {
    margin: 30px 0px 0px;
}
.drag-employees .quadrilles .quadrilles-container {
    display: flex;
}
.drag-employees .quadrilles .quadrilles-container .employees:first-of-type {
    padding: 0px;
}
.drag-employees .quadrilles .quadrilles-container .employees {
    margin: 0px 1rem 0px 0px;
    overflow: hidden;
    padding: 10px;
}
.drag-employees .quadrilles .quadrilles-container .employees .column {
    width: 170px;
    min-height: 400px;
    max-height: 400px;
    background: #E0E0E0;
    padding: 10px;
    border-radius: 3px;
    overflow-y: auto;
}
.drag-employees .quadrilles .quadrilles-container .employees .column .employee-card {
    width: 150px;
    background: white;
    padding: 7px;
    margin: 10px auto;
    border-radius: 5px;
    display: grid !important;
    grid-template-columns: 20% 80%;
    align-items: center;
    cursor: grab;
    position: relative;
    transition: all 0.3s;
}
.drag-employees .quadrilles .quadrilles-container .employees .column .employee-card.searched {
    background: rgb(167, 255, 167);
}
.drag-employees .quadrilles .quadrilles-container .employees .column .employee-card > button {
    position: absolute;
    right: 2px;
    top: 2px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1;
}
.drag-employees .quadrilles .quadrilles-container .employees .column .employee-card > button:hover {
    box-shadow: 0px 0px 8px 4px rgba(169, 209, 255, 0.4);
}
.drag-employees .quadrilles .quadrilles-container .employees .column .employee-card > button > .svg-icon {
    width: 10px;
    height: 10px;
}
.drag-employees .quadrilles .quadrilles-container .employees .column .employee-card.dragged {
    background: rgb(220, 253, 220);
}
.drag-employees .quadrilles .quadrilles-container .employees .column .employee-card:hover {
    box-shadow: 0px 0px 8px 4px rgba(169, 209, 255, 0.4);
}
.drag-employees .quadrilles .quadrilles-container .employees .column .employee-card .svg-icon {
    width: 20px;
    height: 20px;
}
.drag-employees .quadrilles .quadrilles-container .employees .column .employee-card p {
    font-size: 12px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
    width: 90%;
    line-height: 1.7;
}
.drag-employees .quadrilles .quadrilles-container .employees .services-container > h5 {
    margin: 15px 0px 5px;
}
.drag-employees .quadrilles .quadrilles-container .employees .services-select {
    border: none;
    box-shadow: 0px 0px 8px 4px rgba(194, 222, 255, 1);
    font-size: 14px;
    outline-style: none;
    cursor: pointer;
    padding: 10px;
    height: 40px;
    width: 170px;
    margin: 10px 0px;
}