.modal-body .btn-date {
    margin: 30px 0px 0px 0px;
}
.date-info {
    display: grid;
    grid-template-columns: 50% 50%;
}
.initial-date,
.end-date {
    text-align: center;
    color: rgb(77, 1, 1);
}
.initial-date .no-date,
.end-date .no-date {
    color: rgb(223, 1, 1);
    font-weight: 600;
}

