/* Form Contact Me */
.contact {
    width: 100%;
    margin: auto;
    padding: 20px 0px 70px 0px;
    background: #F8F8F8;
    padding: 7%;
}

.contact p:nth-child(2) {
    font-size: 20px;
}

.contact form>input {
    border: none;
    border-radius: 5px;
    width: 90%;
    height: 80px;
    margin: 10px 0px;
    padding: 0px 20px;
    outline-style: none;
    font-size: 15px;
    box-shadow: 0px 0px 8px 4px rgba(194, 222, 255, 0.4);
}
.contact form>p.alert {
    font-size: 15px;
    color: rgb(143, 0, 0);
}
.contact form>input.error {
    border: 2px solid rgb(119, 3, 3);
}

.contact form>input:hover {
    box-shadow: 0px 0px 8px 4px rgba(169, 209, 255, 0.4);
}

.contact form>button {
    width: 20%;
    font-size: 2vw;
    margin: 20px 0px;
}

/* Responsive */
@media (max-width: 768px) {
    .home .home-body .services .services-cards-container .card {
        margin: auto;
    }
    .home .home-body .contact form input {
        height: 65px;
        width: 100%;
    }
    .contact form>button {
        width: 60%;
        font-size: 4.5vw;
    }
}