.promotions {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);;
    z-index: 2;
}
.promotions .promotions-content {
    width: 40%;
    height: 100%;
    margin-left: auto;
    background: white;
    box-shadow: 0px 0px 8px 4px rgba(0, 53, 114, 0.3);
    overflow-y: auto;
    overflow-x: hidden;
    animation: modal-promotions-appear 0.5s;
}
.promotions .promotions-content.disappear {
    animation: modal-disappear 0.5s;
}
.promotions .promotions-content .promotions-header {
    position: relative;
    margin: 0px;
    padding: 10px 20px 10px 20px;
    width: 100%;
    /* height: 40px; */
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    background: white;
    box-shadow: 0px 0px 4px 3px var(--text);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.promotions .promotions-content .promotions-header > h5 {
    width: 80%;
}
.promotions .promotions-content .promotions-header > button {
    position: absolute;
    top: 5px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 3px;
    background: transparent;
}
.promotions .promotions-content .promotions-header > button .svg-icon {
    width: 20px;
    height: 20px;
}
.promotions .promotions-body {
    position: relative;
    display: flex;
    margin: 0px 20px;
}

/* Promotion Image */
.promotions .promotions-body .promotion-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.promotions .promotions-body .data .promotion-image > img {
    max-height: 350px;
    height: auto;
    width: 90%;
    margin: auto;
    object-fit: cover;
}
.promotions .promotions-body .data .promotion-image button {
    border: none;
    background: transparent;
    outline-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 5px;
}
.promotions .promotions-body .data .promotion-image button .svg-icon {
    width:30px;
    height: 30px;
    color: rgb(59, 59, 59);
}
.promotions .promotions-body .data .promotion-image .button:nth-of-type(1) {
    left: -10px;
}
.promotions .promotions-body .data .promotion-image .button:nth-of-type(2) {
    right: -10px;
}
.promotions .promotions-body .data .promotion-image button.disabled {
    cursor: not-allowed;
    pointer-events: none;
}

/* Promotion Data */
.promotions .promotions-body .data {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.promotions .promotions-body .data:nth-child(2),
.promotions .promotions-body .data:nth-child(3) {
    display: none;
}
.promotions .promotions-body .data.appear-right {
    display: block;
    animation: promotion-right-appear 0.3s;
}.promotions .promotions-body .data.appear-left {
    display: block;
    animation: promotion-left-appear 0.3s;
}
.promotions .promotions-body .data.disappear {
    display: none;
}
.promotions .promotions-body .data p:nth-of-type(1) {
    font-weight: bold;
}
.promotions .promotions-body .data p:nth-of-type(3) {
    font-weight: 600;
}
.promotions .promotions-body .data p strong {
    font-weight: 600;
}
.promotions .promotions-body .data p:last-child {
    font-size: 12px;
}

/* Responsive */
@media (max-width: 900px) {
    .promotions .promotions-content {
        width: 60%;
    }
}
@media (max-width: 768px) {
    .promotions .promotions-content {
        height: 90%;
        width: 80%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
    .promotions .promotions-content .promotions-header > h2 {
        font-size: 20px;
        width: 80%;
        text-align: left;
    }
}

/* Animations */
@keyframes modal-promotions-appear {
    0% {
        /* opacity: 0; */
        transform: translateX(100%);
    }
    100% {
        /* opacity: 1; */
        transform: translateX(0px);
    }
}
@keyframes modal-disappear {
    0% {
        /* opacity: 1; */
        transform: translateX(0px);
    }
    99% {
        /* opacity: 1; */
        transform: translateX(100%);
    }
    100% {
        /* opacity: 0; */
        transform: translateX(100%);
    }
}

@keyframes promotion-right-appear {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    99% {
        opacity: 1;
        transform: translateX(0%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}
@keyframes promotion-left-appear {
    0% {
        opacity: 1;
        transform: translateX(0%);
    }
    99% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}