.select-quadrille-modal > .btn-add {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin: 10px 0px 20px 0px;
}
.select-quadrille-modal form {
    margin: 30px 0px 10px 0px;
}
.select-quadrille-modal .quadrilles-data {
    margin: 15px 0px;
    padding: 10px;
    position: relative;
}
.select-quadrille-modal .quadrilles-data .radio {
    display: flex;
    align-items: center;
}
.select-quadrille-modal .quadrilles-data .radio label {
    margin: 0px 1.1rem;
}
.select-quadrille-modal .quadrilles-data .extra-info {
    margin: -5px 0px 0px 2rem;
    padding: 0px;
    font-size: 12px;
}
.select-quadrille-modal .quadrilles-data:hover {
    background: rgb(226, 255, 226);
}
.select-quadrille-modal .quadrilles-data p {
    line-height: 1;
    font-size: 12px;
}
.select-quadrille-modal .quadrilles-data .remove-quadrille {
    position: absolute;
    right: 0;
    top: 17px;
    float: right;
    z-index: 1;
    width: 30px;
    height: 30px;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.select-quadrille-modal .quadrilles-data .remove-quadrille:hover {
    background: rgb(138, 0, 0);
    color: rgb(255, 255, 255);
}