/* Footer */
.home-footer {
  height: auto;
  width: 100%;
  padding: 7%;
}

.home-footer .data-container .data p {
  font-size: 20px;
  font-weight: bold;
}

.home-footer .data-container .data ul {
  list-style: none;
  padding: 0px;
}

.home-footer .data-container .data ul li,
.home-footer .data-container .data ul li a {
  margin: 20px auto;
  font-size: 15px;
  cursor: pointer;
  color: rgb(41, 41, 41);
}

.home-footer .data-container .data-2 ul li:nth-of-type(1),
.home-footer .data-container .data-2 ul li:nth-of-type(2),
.home-footer .data-container .data-2 ul li:nth-of-type(3) {
  cursor: text;
}

.home-footer .data-container .data ul li.active {
  font-weight: bold;
}

.home-footer>p {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 70px auto 0px 0px;
}

/* Animations */
@media (max-width: 768px) {
  .home-footer > p {
    margin: 50px 0px 100px 0px;
    font-size: 18px;
  }
}