.services-home .services-header {
    padding: 30px 7% 0px;
}
.services-home .services-header p:nth-of-type(2) {
    font-size: 20px;
}

.services-home .services-body .services-cards-container {
    padding: 50px 7% 70px;
}
.services-home .services-body .services-cards-container .card {
    width: 100%;
    margin: 0px;
    position: relative;
    box-shadow: 0px 0px 5px 3px rgba(118, 171, 255, 0.1);
}
.services-home .services-body .services-cards-container .card > img {
    height: 180px;
    /* height: auto; */
    width: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
}
.services-home .services-body .services-cards-container .card > p:nth-of-type(1) {
    font-size: 18px;
    font-weight: 600;
}
.services-home .services-body .services-cards-container .card > p:nth-of-type(2) {
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 30%;
}
.services-home .services-body .services-cards-container .card > p:nth-of-type(2) .svg-icon {
    height: 24px;
    width: 24px;
    color: rgb(93, 93, 93);
}

/* Responsive */
@media (max-width: 768px) {
    .services-home .services-body .services-cards-container {
        padding: 30px 7% 30px 7%;
    }
}