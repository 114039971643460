.report-1 .multi-select {
    border: none;
    box-shadow: 0px 0px 8px 4px rgba(194, 222, 255, 0.4);
    font-size: 14px;
    outline-style: none;
    cursor: pointer;
    padding: 10px 20px;
    min-height: 10px;
    height: auto;
}
.report-1 .vehicles-container form .data .vehicles-selecteds {
    width: auto;
}
.report-1 .vehicles-container form .data .vehicles-selecteds .vehicle-selected {
    display: flex;
    align-items: center;
    width: auto;
}
.report-1 .vehicles-container form .data .vehicles-selecteds .vehicle-selected:hover {
    background: rgb(248, 253, 255);
}
.report-1 .vehicles-container .vehicles-selecteds .vehicle-selected p {
    margin-right: 10px;
}
.report-1 .vehicles-container .vehicles-selecteds .vehicle-selected .remove-quadrille {
    width: 20px;
    height: 20px;
    padding: 3px;
    border: none;
}
.report-1 .remove-quadrille > .svg.icon {
    height: 20px;
    width: 20px;
}
.report-1 .vehicles-container form > button {
    background: green;
}