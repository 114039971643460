.__alert__wrapper {
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.4);
}
.__alert__wrapper .__alert__container,
.__alert__wrapper .__alert__container.not-image {
    position: absolute;
    bottom: 0;
    height: 25%;
    width: 50%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: white;
    animation-name: alert-appear;
    animation-duration: 0.6s;
    z-index: 10;
    padding: 20px 30px 20px 30px;
    overflow: auto;
    border-radius: 10px;
}
.__alert__wrapper .__alert__container.not-image {
    height: 180px;
}
.__alert__wrapper .__alert__container .__alert__header {
    position: relative;
}
.__alert__wrapper .__alert__container .__alert__header p {
    font-size: 20px;
    font-weight: bold;
    margin: 0px;
}
.__alert__wrapper .__alert__container .__alert__header > .__close__alert {
    position: absolute;
    top: 0px;
    right: 10px;
    border: none;
    border-radius: 3px;
    background-color: transparent;
    cursor: pointer;
    box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    outline-style: none;
}
.__alert__wrapper .__alert__container .__alert__header > .__close__alert:hover {
    box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.2);
}
.__alert__wrapper .__alert__container > .__alert__body {
    line-height: 1.5;
    padding: 0px;
}
.__alert__wrapper .__alert__container > .__alert__body p.not-image {
    height: 27px;
}
.__alert__wrapper .__alert__container > .__alert__body > p {
    margin: 5px 0px;;
    padding: 0px;
}
.__alert__wrapper .__alert__container > .__alert__body > p:last-child {
    margin: 5px 0px 10px 0px;
}
.__alert__wrapper .__alert__container > .__alert__footer {
    display: flex;
}
.__alert__wrapper .__alert__container > .__alert__footer > button {
    width: 140px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: white;
    box-shadow: 1px 2px 10px 6px rgba(0, 0, 0, 0.2);
    transition: all 0.5s;
    outline-style: none;
    cursor: pointer;
    margin: 0px;
    font-size: 15px;
}
.__alert__wrapper .__alert__container > .__alert__footer > button:hover {
    font-weight: bold;
    box-shadow: 1px 2px 10px 6px rgba(0, 0, 0, 0.3);
}
.__alert__wrapper .__alert__container > .__alert__footer > button:nth-child(1) {
    background-color: rgb(13, 211, 13);
}
.__alert__wrapper .__alert__container > .__alert__footer > button:nth-child(2) {
    background-color: white;
    color: black;
    margin-left: 30px;
}

/* Responsive */

@media (max-width: 768px) {
    .__alert__wrapper .__alert__container{
        width: 80%;
    }
    .__alert__wrapper .__alert__container.not-image {
        width: 80%;
        height: 150px;
    }
    .__alert__wrapper .__alert__container > .__alert__footer > button {
        max-width: 100%;
    }
}

/* Animations */

@keyframes alert-appear {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}