.services-admin > .btn-add {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin: 30px 0px 0px 0px;
}
.services-admin {
    width: 100%;
}
.services-admin .filter-services-container {
    overflow: hidden;
    width: 100%;
    margin: 15px 0px;
}
.services-admin .filter-services-container .items {
    width: 100%;
    display: flex;
}
.services-admin .filter-services-container p {
    margin: 20px 6% 0px 12px;
    cursor: pointer;
    position: relative;
    text-align: center;
}
.services-admin .filter-services-container p.active,
.services-admin .filter-services-container p:hover {
    color: #1fb7c2;
    font-weight: 600;
}
.services-admin .filter-services-container p:nth-child(1)::before {
    width: 130% !important;
}
.services-admin .filter-services-container p.active::before {
    content: '';
    width: 115%;
    height: 3px;
    background-color: green;
    position: absolute;
    top: 24px;
    left: -5px;
    border-radius: 5px;
}

.services-admin .services-data {
    width: 100%;
    height: auto;
    background: #E0E0E0;
    padding: 35px 35px 10px 35px;
    border-radius: 3px;
    margin: 30px auto 0px auto;
}
.services-admin .services-data .service-container {
    position: relative;
    width: auto;
    height: auto;
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 3px;
    margin: 0px 0px 30px 0px;
    box-shadow: 0px 0px 8px 4px rgba(194, 222, 255, 0.4);
}
.services-admin .services-data .service-container:hover {
    box-shadow: 0px 0px 8px 4px rgba(169, 209, 255, 0.4);
    background: rgb(232, 255, 232);
}
.services-admin .services-data .service-container .tag {
    position: absolute;
    right: -5px;
    top: 8px;
    width: 30px;
    height: 5px;
    background: red;
    transform: rotate(45deg);
}
.services-admin .services-data .service-container p {
    margin: 5px;
}
.services-admin .services-data .service-container .extra-info {
    display: flex;
    position: relative;
}
.services-admin .services-data .service-container .line-elements {
    position: relative;
}
.services-admin .services-data .service-container .service-title {
    font-size: 17px;
    font-weight: 600;
    width: 80%;
    position: sticky;
    left: 0;
}
.services-admin .services-data .service-container .line-elements .btn-options {
    background: red;
}
.services-admin .services-data .service-container .line-elements .btn-options > button {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border: none;
    background: none;
    outline-style: none;
}
.services-admin .services-data .service-container .line-elements .btn-options > button .svg-icon {
    width: 20px;
    height: 20px;
    color: rgb(61, 61, 61);
}
.services-admin .services-data .service-container .line-elements .btn-options ul {
    position: absolute;
    background: white;
    top: 15px;
    right: 15px;
    padding: 0px;
    list-style: none;
    box-shadow: 0px 0px 8px 4px rgba(194, 222, 255, 0.4);
    display: none;
    z-index: 1;
}
.services-admin .services-data .service-container .line-elements .btn-options ul.active {
    display: block;
}
.services-admin .services-data .service-container .line-elements .btn-options ul a {
    text-decoration: none;
}
.services-admin .services-data .service-container .line-elements .btn-options ul li {
    font-size: 12px;
    padding: 8px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.services-admin .services-data .service-container .line-elements .btn-options ul li:hover {
    background: rgb(220, 245, 255);
}
.services-admin .services-data .service-container .line-elements .btn-options ul li .svg-icon {
    width: 18px;
    height: 18px;
    color: rgb(75, 75, 75);
    margin-right: 8px;
}
.services-admin .services-data .service-container .service-description {
    margin: 15px 5px;
}
.services-admin .services-data .service-container .company,
.services-admin .services-data .service-container .date {
    font-size: 13px;
    margin: 10px 5px;
}
.services-admin .services-data .service-container .date {
    position: absolute;
    right: 0;
}



/* Responsive */
@media only screen and (max-width: 768px) {
    .services-admin .filter-services-container {
        overflow: hidden;
        width: 100%;
        margin: 0px;
    }
    .services-admin .filter-services-container .items {
        display: flex;
        overflow-x: auto !important;
        width: 100%;
    }
    .services-admin .filter-services-container p:nth-child(1)::before {
        width: 110% !important;
    }
    .services-admin .filter-services-container p::before {
        min-width: 100%;
    }
    .services-admin .filter-services-container p {
        margin: 30px 20px 0px 0px;
        font-size: 13px;
        min-width: 35%;
        width: auto;
        padding: 0px;
    }

    .services-admin .services-data {
        width: 100%;
        height: auto;
        padding: 0px;
        background: white;
    }
    .services-admin .services-data .service-container {
        width: 100%;
        height: auto;
        background: rgb(255, 255, 255);
        border-radius: 3px;
        padding: 10px;
    }
    .services-admin .services-data .service-container p {
        margin: 5px;
    }
    .services-admin .services-data .service-container .service-title {
        font-size: 15px;
        font-weight: 600;
    }
    .services-admin .services-data .service-container .service-description {
        font-size: 13px;
        margin: 5px;
    }
    .services-admin .services-data .service-container .extra-info {
        display: block;
        margin: 15px 0px;
    }
    .services-admin .services-data .service-container .extra-info > p {
        font-size: 12px;
        display: block;
    }
    .services-admin .services-data .service-container .date {
        position: static;
        margin: -5px 5px;
    }
}