.alert-confirm > div {
    display: flex;
    overflow: auto;
    margin: 4rem 0px;
    padding: 10px;
}
.alert-confirm .report {
    width: auto;
    background: #f8fffe;
    margin: 0px 10px 0px 20px;
    padding: 5px 10px;
    min-height: 200px;
}
.alert-confirm .report:hover {
    box-shadow: 0px 0px 8px 4px rgba(169, 209, 255, 0.3);
}
.alert-confirm .report > p:first-of-type {
    font-weight: 600;
    margin: 10px 0px 0px;
}
.alert-confirm .report .column-data {
    width: 150px;
    height: auto;
    padding: 10px 0px;
    border-radius: 3px;
}
.alert-confirm .report .column-data p {
    color: green;
    font-size: 14px;
}
.alert-confirm .report .service-container > p {
    margin: 0px;
    font-size: 12px;
}
.alert-confirm .report .service-container > p:first-of-type {
    font-weight: 600;
    font-style: italic;
}