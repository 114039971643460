.show-quadrilles button {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin: 10px 0px 20px 0px;
    box-shadow: none;
    color: white;
}
.show-quadrilles .btn-show {
    background: green;
}
.show-quadrilles .btn-edit {
    background: rgb(126, 126, 63);
}
