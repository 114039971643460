.home {
  background: white !important;
}

.home-header {
  padding: 20px;
  height: 90vh;
  width: 90%;
  margin: 0px auto;
  display: grid;
  grid-template-columns: 42% auto;
  position: relative;
}

.home-header .text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-header .text .main-text {
  width: 90%;
  margin-right: auto;
}
.home-header .text .main-text > h1 {
  line-height: 1;
}
.home-header .text .main-text p {
  margin: 5px;
}

.home-header .text .main-text p:nth-child(1) {
  line-height: 1.5;
}

.home-header .text .main-text p:nth-child(2) {
  line-height: 1.2;
  font-weight: 500;
}

.home-header .text .main-text button {
  width: 90%;
  font-size: 2.2vw;
  margin: 50px 0px 0px 0px;
}

.home-header .text .main-text button:hover,
.home-header .contact form>button:hover {
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.3);
}

.home .home-header .image-container {
  position: relative;
  margin: 20px auto;
}
.home .home-header .image-container img {
  position: absolute;
  max-height: 100%;
  height: auto;
  width: 33%;
  top: 0;
  bottom: 0;
  margin: auto;
  background-size: 0, cover;
  transform-style: preserve-3d;
  perspective: 1000px;
  object-fit: cover;
  transition: all 0.3s;
  border-radius: 5px;
  transform: rotateX(25deg) rotateY(15deg);
}
.home .home-header .image-container .skeleton-image {
  margin: auto;
  transition: all 0.3s;
  background: rgb(204, 204, 204);
  background-size: 0, cover;
  transform-style: preserve-3d;
  transform: rotateX(25deg) rotateY(15deg);
  transform: translate(-50% -50%);
  top: 0;
  bottom: 0;
  margin: auto;
}
.home .home-header .image-container .skeleton-image:hover,
.home .home-header .image-container .left:hover,
.home .home-header .image-container .mid:hover, 
.home .home-header .image-container .right:hover {
  transform: rotateX(0deg) rotateY(0deg);
}
.home .home-header .image-container .left,
.home .home-header .image-container .skeleton-left {
  position: absolute;
  height: 200px;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translateX(135deg) translateY(50deg);
  background-position: 78% 50%;
}
.home .home-header .image-container .skeleton-left {
  width: 150px;
  height: 200px;
}
.home .home-header .image-container .mid,
.home .home-header .image-container .skeleton-mid {
  position: absolute;
  height: 350px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.home .home-header .image-container .skeleton-mid {
  width: 200px;
  height: 310px;
}
.home .home-header .image-container .right,
.home .home-header .image-container .skeleton-right {
  position: absolute;
  height: 200px;
  right: 0;
  top: 0;
  bottom: 0;
}
.home .home-header .image-container .skeleton-right {
  width: 150px;
  height: 200px;
}

/* .thumb {
  width: 100%;
  height: auto;
  margin: 50px auto;
  perspective: 1000px;
}

.thumb div {
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
  url("https://image.ibb.co/dms0AG/image.jpg");
  background-size: 0, cover;
  transform-style: preserve-3d;
  transition: all 0.5s;
}

.thumb:hover .imageRef {
  transform: rotateX(80deg);
  transform-origin: bottom;
}
.thumb .imageRef:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 36px;
  background: inherit;
  background-size: cover, cover;
  background-position: bottom;
  transform: rotateX(90deg);
  transform-origin: bottom;
}
.thumb .imageRef span {
  color: white;
  text-transform: uppercase;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font: bold 12px/36px "Open Sans";
  text-align: center;
  transform: rotateX(-89.99deg);
  transform-origin: top;
  z-index: 1;
}
.thumb .imageRef:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 100px 50px rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
  opacity: 0.15;
  transform: rotateX(95deg) translateZ(-80px) scale(0.75);
  transform-origin: bottom;
}

.thumb:hover .imageRef:before {
  opacity: 1;
  box-shadow: 0 0 25px 25px rgba(0, 0, 0, 0.5);
  transform: rotateX(0) translateZ(-60px) scale(0.85);
} */








.home-header .image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-header .image-container img:hover {
  filter: grayscale(1);
}
.home-header .logo-image-right {
  position: absolute;
  height: 100px;
  width: 100px;
  right: 0;
  left: 0;
  top: -20px;
  margin: auto;
}

/* Body */
.home .home-body .companies {
  width: 90%;
  margin: 0px auto;
  height: auto;
  padding: 20px;
}

.home .home-body .companies>img {
  height: auto;
  width: 100px;
  object-fit: cover;
  margin: 0px 20px 0px 0px;
  display: inline-block;
}

/* About US*/
.home .home-body .about-us {
  display: grid;
  grid-template-columns: 50% 50%;
  background: #F8F8F8;
  width: 100%;
  margin: 0px auto;
  padding: 0px 7%;
}

.home .home-body .about-us .text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.home .home-body .about-us .text .text-data {
  width: 95%;
  margin-right: auto;
}

.home .home-body .about-us .text .text-data p {
  line-height: 1.5;
  margin: 5px 0px;
}

.home .home-body .about-us .text .text-data p:last-child {
  margin: 0px;
}

.home .home-body .about-us .image-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home .home-body .about-us .image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Services */
.services {
  width: 100%;
  margin: 0px auto;
  padding: 7%;
}

.services .services-cards-container {
  margin: auto;
}

.services .services-cards-container .card {
  padding: 20px;
  margin: 0px 5px 20px 0px;
  min-height: 200px;
  height: auto;
  width: auto;
  cursor: pointer;
  background: rgb(249, 252, 255);
}

.services .card p {
  cursor: pointer;
}

.services .card p:nth-child(1) {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.services .card>p:nth-child(2) {
  font-size: 18px;
  text-align: justify;
  line-height: 1.5;
}

.services>button {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  border: none;
  width: auto;
  height: auto;
  padding: 10px 20px;
  background: transparent;
  margin: 25px auto;
  display: block;
  color: rgb(41, 41, 41);
}
.btn-promotion {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: rgb(0, 174, 255);
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 8px rgba(0, 129, 146, 0.2);
  animation: promotion-jump 5s infinite;
  transition: all 0.3s;
}
.btn-promotion:hover {
  bottom: 25px;
  box-shadow: 0px 0px 10px 8px rgba(0, 129, 146, 0.3);
}
.btn-promotion .svg-icon {
  height: 24px;
  width: 24px;
  color: white;
}

/* Animations */
@keyframes promotion-jump {
  0% {
    bottom: 20px;
  }
  25% {
    bottom: 25px;
  }
  50% {
    bottom: 20px;;
  }
  75% {
    bottom: 20px;;
  }
  100% {
    bottom: 20px;
  }
}
@keyframes skeleton {
  0% {
    
  }
}

/* Responsive */
@media only screen and (min-width: 1900px) {
  .home .home-header .image-container .left {
    height: 400px;
  }
  .home .home-header .image-container .mid {
    height: 550px;
  }
  .home .home-header .image-container .right {
    height: 400px;
  }
}
@media (max-width: 768px) {
  .home-header {
    grid-template-columns: 100%
  }

  .title {
    font-size: 7vw;
  }

  .home-header .text {
    display: block;
    margin: 10px 0px;
  }

  .home-header .text .main-text {
    width: 100%;
  }

  .home-header .text .display-1 {
    font-size: 12vw;
    text-align: center;
  }

  .home-header .text .display-4 {
    font-size: 7vw;
    text-align: center;
  }

  .home-header .text .main-text button {
    margin: auto;
    display: block;
    margin: 20px auto;
    height: auto;
    font-size: 5vw;
    padding: 20px 0px;
  }

  .home-header .image-container {
    width: 100%;
    min-height: 200px;
    height: 300px;
  }

  .home-body .companies {
    display: grid;
    margin: auto;
  }

  .home-body .companies>img {
    margin: 10px auto 10px auto !important;
    display: block;
    width: 100%;
  }

  .home .home-body .about-us {
    grid-template-columns: 100%;
    padding: 7%;
  }

  .home .home-body .about-us .text {
    text-align: justify;
  }

  .home .home-body .about-us .text .text-data .title {
    font-size: 7vw;
  }

  .home .home-body .about-us .text .text-data p {
    font-size: 4vw;
    margin: 10px auto;
  }

  .home .home-body .about-us .image-container img {
    height: 80%;
    width: 80%;
  }
  .btn-promotion {
    bottom: 75px !important;
  }
}

/* body { background: gainsboro; }
.pageOption {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  width: 40em; height: 27em;
  border-radius: 50px;
}
.option {
    border-radius: 50px 100px 50px 50px;
}
.option, .option img { width: 100%; height: 100%; }
.option {
  overflow: hidden;
  position: absolute;  
  transform: skewX(-55.98deg);
}
.option:first-child {
  left: -.25em;
  transform-origin: 100% 0;
}
.option:last-child {
  right: -.25em;
  transform-origin: 0 100%;
}
.option img { opacity: .75; transition: .5s; }
.option img:hover { opacity: 1; }
.option img, .option:after {
  transform: skewX(55.98deg);
  transform-origin: inherit;
}
.option:after {
  position: absolute;
  margin: .5em 1.65em;
  color: white;
  font: 500 1.25em Courier;
  letter-spacing: .1em;
  text-transform: uppercase;
  content: attr(data-inf);
}
.option:first-child:after { top: 0; left: 0; }
.option:last-child:after { right: 0; bottom: 0; } */