.category-search .search-container .input-search {
    margin: 20px 0px 0px 0px;
}

@media only screen and (max-width: 768px) {
    .category-search {
        display: block;
    }
    .category-search .search-container .input-search {
        width: 100%;
        margin: 20px 0px 0px 0px;
    }
}