.navbar-home {
    width: 100%;
    height: 70px;
    display: grid;
    grid-template-columns: 40% auto;
    z-index: 1;
}
.navbar-home .notification.active {
    position: fixed;
    right: 20px;
    top: 20px;
    width: auto;
    height: auto;
    background: #09bcdb;
    padding: 20px;
    z-index: 2;
    border-radius: 5px;
    animation: notification-appear 0.3s;
    display: block;
}
.navbar-home .notification {
    display: none;
}
.navbar-home .notification > button {
    width: auto;
    height: auto;
    background: rgb(54, 191, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    position: absolute;
    right: 20px;
    top: 8px;
}
.navbar-home .notification > button > .svg-icon {
    width: 18px;
    height: 18px;
    color: white;
}
.navbar-home .notification > p {
    color: white;
    text-align: right;
    width: 100%;
}
.navbar-home .image-container {
    display: flex;
    align-items: center;
    height: 70px;
    position: relative;
    /* padding: 0px 20px; */
}
.navbar-home .image-container > img {
    height: 100px !important;
    width: 100px !important;
    margin-left: 20px;
    float: left;
    position: absolute;
    left: 10%;
    cursor: pointer;
}
.navbar-home > button {
    display: none;
}

.navbar-home .options > ul > button {
    display: none;
}
.navbar-home .options ul {
    width: 100%;
    list-style: none;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 20px auto 0px;
    height: 70px;
}
.navbar-home .options ul li {
    width: auto;
    margin: auto 20px auto 20px;
    cursor: pointer;
    font-size: 15px;
    font-family: 'Raleway', sans-serif;
    position: relative;
    transition: all 0.3s;
}
.navbar-home .options ul li::after {
    content: '';
    width: 0;
    left: -25%;
    height: 5px;
    background: #b9b9b9;
    position: absolute;
    top: 30px;
    transition: width 0.35s ease 0s;
    border-radius: 20px;
}
.navbar-home .options ul li.active::after {
    content: '';
    width: 150%;
    left: -25%;
    height: 5px;
    background: #09bcdb;
    position: absolute;
    top: 30px;
    transition: width 0.35s ease 0s;
    border-radius: 20px;
    font-weight: 800;
    animation: nav-link-appear 0.3s;
}
.navbar-home .options ul li:hover::after {
    width: 150%;
    left: -25%;
}
.navbar-home .navbar-bottom {
    display: none;
}

/* Responsive */
@media (max-width: 900px) {
    .navbar-home .options ul li {
        font-size: 13px;
        text-align: center;
        margin: auto .6rem auto .6rem;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .navbar-home .notification.active {
        top: 0;
        right: 0;
        z-index: 5;
    }
    .navbar-home .notification > p {
        font-weight: 600;
        font-size: 18px;
    }
    .navbar-home > button {
        width: 100px;
        margin-left: auto;
        background: transparent;
        display: block;
    }
    .navbar-home > button .svg-icon {
        height: 24px;
        width: 24px;
        transform: rotate(180deg);
    }
    .navbar-home .options {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        margin: auto;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
        z-index: 1;
        overflow: hidden;
        display: none;
    }
    .navbar-home .options.show {
        display: block;
        z-index: 2;
    }
    .navbar-home .options ul {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 70%;
        margin: 0px;
        background: rgb(150, 77, 77);
        display: block;
        animation: navbar-appear 0.3s;
    }
    .navbar-home .options ul.remove {
        animation: navbar-disappear 0.3s;
    }
    .navbar-home .options > ul > button {
        display: block;
    }
    .navbar-home .options ul button {
        position: absolute;
        right: 5px;
        top: 10px;
        z-index: 1;
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: transparent;
    }
    .navbar-home .options ul li {
        height: 25%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(255, 255, 255);
        font-size: 18px;
        margin: auto;
        cursor: pointer;
    }
    .navbar-home .options ul li:hover {
        background: rgb(231, 231, 231);
        font-weight: 600;
    }
    .navbar-home .options ul li.active {
        background: rgb(215, 237, 255);
        font-weight: 600;
        color: rgb(0, 96, 175);
    }
    .navbar-home .options ul li::after,
    .navbar-home .options ul li.active::after {
        background: transparent;
    }

    /* Navbar Bottom */
    .navbar-home .navbar-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 60px;
        display: block;
        box-shadow: 0px 0px 10px 8px rgba(0, 183, 255, 0.4);
    }
    .navbar-home .navbar-bottom > ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        height: 60px;
        display: flex;
        width: 100%;
        position: relative;
    }
    .navbar-home .navbar-bottom > ul li {
        width: 100%;
        /* height: 60px; */
        margin: 0px auto;
        padding: 10px;
        background: rgb(0, 174, 255);
        position: sticky;
        top: 0;
        left: 0;
        text-align: center;
        color: white;
        display: block;
        cursor: pointer;
    }
    .navbar-home .navbar-bottom > ul li:hover {
        background: rgb(54, 191, 255);
    }
    .navbar-home .navbar-bottom > ul li > p {
        margin: -2px auto 0px auto;
        color: white;
        font-size: 13px;
    }
    .navbar-home .navbar-bottom > ul li .svg-icon {
        width: 20px;
        height: 20px;
        margin: auto;
    }
}

/* Animations */
@keyframes nav-link-appear {
    0% {
        width: 0%;
    }
    100% {
        width: 150%;
    }
}
@keyframes navbar-appear {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes navbar-disappear {
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(100%);
    }
}
@keyframes notification-appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}