.service-data-container {
    width: 100%;
    height: auto;
    background: #E0E0E0;
    padding: 35px 35px 10px 35px;
    border-radius: 3px;
    margin: 30px auto 0px auto;
}

.service-data-container .service-data {
    position: relative;
    width: auto;
    height: auto;
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 3px;
    margin: 0px 0px 30px 0px;
    box-shadow: 0px 0px 8px 4px rgba(194, 222, 255, 0.4);
    transition: all 0.3s;
    cursor: pointer;
}

.service-data-container .service-data:hover {
    background: rgb(232, 255, 232);
    box-shadow: 0px 0px 8px 4px rgba(169, 209, 255, 0.4);
}

.service-data-container .service-data .tag {
    position: absolute;
    right: -5px;
    top: 8px;
    width: 30px;
    height: 5px;
    background: red;
    transform: rotate(45deg);
}

.service-data-container .service-data>p:first-of-type {
    font-size: 17px;
    font-weight: 600;
}

.service-data-container .service-data>p:nth-child(3) {
    margin: 15px 0px;
}

.service-data-container .service-data .text-data {
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.service-data-container .service-data .text-data>p:nth-child(1),
.service-data-container .service-data .text-data>p:nth-child(2),
.service-data-container .service-data .text-data>p:nth-child(3) {
    font-size: 13px;
    margin: 10px 0px;
}

.service-data-container .service-data .text-data>p strong,
.service-data-container .service-data .text-data>p span {
    margin-left: 5px;
}

.service-data-container .service-data .text-data>p .no-finish {
    color: rgb(192, 0, 0);
    font-weight: 600;
}

/* Material Requested */
.material-requested .service-data .products-container .data {
    display: flex;
}
.material-requested .service-data .products-container .data > p {
    margin-right: 10px;
}
.material-requested .service-data .products-container .data > p:last-of-type {
    font-weight: 600;
}

/* Quadrilles */
.material-requested .service-data .products-container .quadrille-container {
    margin-bottom: 15px;
}
.material-requested .service-data .products-container .quadrille-container .quadrilles-data {
    display: grid;
    grid-template-columns: auto auto auto;
}
.material-requested .service-data .products-container .quadrille-container .quadrilles-data .quadrille {
    font-size: 12px;
    font-weight: 600;
    color: white;
    background: green;
    border-radius: 10px;
    padding: 5px 10px;
    margin-right: 5px;
}

/* Responsive */
@media only screen and (max-width: 768px) {
    .service-data-container {
        width: 100%;
        height: auto;
        padding: 0px;
        background: white;
    }

    .service-container>h3 {
        font-size: 17px;
    }

    .service-data-container .service-data {
        width: 100%;
        height: auto;
        background: rgb(255, 255, 255);
        border-radius: 3px;
        padding: 10px;
        box-shadow: 0px 5px 8px 7px rgba(170, 210, 255, 0.4);
    }

    .service-data-container .service-data:hover {
        box-shadow: 0px 8px 8px 7px rgba(138, 193, 255, 0.4);
    }

    .service-data-container .service-data>p:nth-child(1) {
        font-size: 17px;
        font-weight: 600;
    }

    .service-data-container .service-data>p:nth-child(2) {
        margin: 15px 5px;
    }

    .service-data-container .service-data .text-data {
        display: block;
    }

    .service-data-container .service-data .text-data>p:nth-child(1),
    .service-data-container .service-data .text-data>p:nth-child(2),
    .service-data-container .service-data .text-data>p:nth-child(3) {
        font-size: 12px;
        margin: -5px 5px;
    }

    .service-data-container .service-data .text-data>p strong,
    .service-data-container .service-data .text-data>p span {
        margin-left: 20px;
    }

    /* Material Request */

    /* Quadrilles */
    .material-requested .service-data .products-container .quadrille-container .quadrilles-data {
        display: block;
        width: 100%;
    }
    .material-requested .service-data .products-container .quadrille-container .quadrilles-data .quadrille {
        width: 100px;
        margin: 5px 0px 10px 0px;
        text-align: center;
    }
}