.modal .modal-content {
    width: 90%;
    height: 90%;
    animation: modal-services-appear 0.5s;
}
.modal .modal-content .modal-header .btn-close-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin-top: -3px;
}
.modal .modal-content .modal-header .btn-close-modal .svg-icon {
    height: 20px;
    width: 20px;
    color: rgb(42, 42, 42);
}
.modal .modal-content .modal-body {
    width: 90%;
    margin: auto;
}
.modal .modal-content .modal-body .service-image {
    width: 100%;
    height: auto;
    margin: auto;
    display: block;
    object-fit: cover;
    border-radius: 5px;
}
.modal .modal-content .modal-services-ws p:nth-of-type(1) {
    font-size: 20px;
    font-weight: 600;
}
.modal .modal-content .modal-services-ws p:nth-of-type(2) {
    font-size: 16px;
}

/* Animations Modal */
@keyframes modal-services-appear {
    0% {
        width: 0px;
        height: 0px;
    }

    100% {
        width: 90%;
        height: 90%;
    }
}

/* Responsive */
@media only screen and (max-width: 768px) {
    .modal .modal-content .modal-body p:nth-of-type(1) {
        font-size: 16px;
    }
    .modal .modal-content .modal-body p:nth-of-type(2) {
        font-size: 14px;
    }
}