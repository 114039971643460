.modal .modal-content .modal-body form .input-container .items > ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    box-shadow: 0px 0px 8px 4px rgba(194, 222, 255, 0.4);
    max-height: 100px;
    overflow-x: auto;
    display: none;
}
.modal .modal-content .modal-body form .input-container .items > ul.active {
    display: block;
}
.modal .modal-content .modal-body form .input-container .items > ul:hover {
    box-shadow: 0px 0px 8px 4px rgba(169, 209, 255, 0.4);
}
.modal .modal-content .modal-body form .input-container .items > ul li {
    font-size: 14px;
    padding: 5px;
    cursor: pointer;
}
.modal .modal-content .modal-body form .input-container .items > ul li:hover {
    background: rgb(242, 250, 255);
}